import { parseAsInteger, parseAsString } from "nuqs";
import { createSearchParamsCache } from "nuqs/server";

export const DashboardParams = {
   focus_item_id: parseAsString,

   focus_comment_thread_id: parseAsString,
   focusedOn: parseAsInteger.withDefault(1),
   revalidate: parseAsString
};

export const DashboardParamsCache = createSearchParamsCache(DashboardParams);
