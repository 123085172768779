import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Editor } from "@tiptap/core";
import { Mark } from "@tiptap/pm/model";
import ToggleRightNav from "app/(platform)/_components/ToggleRightNav";
import Header from "app/_components_v2/data-display/Header";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import { useQueryStates } from "nuqs";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FocusItemSetterContext } from "../_contexts/FocusItemProvider";
import { EditorDocumentIdContext, ToolbarEditorContext } from "../_contexts/ToolbarProvider";
import { CommentThread } from "./Chat";
import { DashboardParams } from "./DashboardParams";
import { LinkItem } from "./LinkItem";
import { FocusItemMarkAttributes } from "./nodes/defaultNodeAttributes";
type MagnifyingGlassProps = {
   editable: boolean;
   token: string | undefined;
   role: PA.RoleQueries | undefined;
};
/* 

*/
type FocusItemProps = { attributes: FocusItemMarkAttributes; commentThreadId: string };

function MagnifyingGlass({ editable, token, role }: MagnifyingGlassProps) {
   const containerRef = useRef<HTMLDivElement>(null);
   const { toolbarEditor } = useContext(ToolbarEditorContext);
   const { editorDocumentId } = useContext(EditorDocumentIdContext);
   const { setFocusedItemId } = useContext(FocusItemSetterContext);
   const [{ focusedOn, focus_item_id, focus_comment_thread_id, revalidate }, setQueryParams] = useQueryStates(DashboardParams);
   const [dedupedItems, setDedupedItems] = useState<FocusItemProps[]>([]);

   useEffect(() => {
      onDebounce({ editor: toolbarEditor });
   }, [editorDocumentId, focus_comment_thread_id, focus_item_id, revalidate]);

   function onDebounce({ editor }: { editor: Editor | null | undefined }) {
      if (editor === null || editor === undefined) return;
      let items: Mark[] = [];
      editor.state.doc.descendants((node) => {
         const mark = node.marks.find((mark) => mark.type.name === "focusItem");
         if (mark) {
            items.push(mark);
         }
      });
      let allThreads = new Map<string, FocusItemProps>();
      Array.from(items).map((mark) => {
         const attributes = mark.attrs as FocusItemMarkAttributes;
         if (!attributes.chatIds || attributes.chatIds?.length === 0) {
            if (attributes.type === "link" && attributes.focusItemId) {
               allThreads.set(attributes.focusItemId, { commentThreadId: "", attributes });
            }
         } else {
            attributes.chatIds.forEach((commentThreadId) => {
               const existing = allThreads.get(commentThreadId);
               if (!!existing) {
                  if (existing.commentThreadId === commentThreadId) {
                     allThreads.set(commentThreadId, { commentThreadId, attributes });
                  }
               } else {
                  allThreads.set(commentThreadId, { commentThreadId, attributes });
               }
            });
         }
      });
      let finalItems = Array.from(allThreads.values());
      finalItems.unshift({
         commentThreadId: "",
         attributes: { chatIds: [], base64: "", focusItemId: "", href: "", tagId: "", title: "", type: "comment", personId: "" }
      });
      ILog.v("FINAL_ITEMS_MagnifyingGlass", { finalItems, items, allThreads, editor });
      setDedupedItems(finalItems);
   }

   const focusedItem = useMemo(() => {
      let item = dedupedItems.find((item) => item.commentThreadId === focus_comment_thread_id);
      if (!item) {
         item = dedupedItems.find((item) => item.commentThreadId === focus_item_id);
      }

      ILog.v("MagnifyingGlass_focusedItem", { item, focus_comment_thread_id });
      return item;
   }, [dedupedItems, focusedOn, focus_item_id, focus_comment_thread_id, toolbarEditor]);

   const { item: fastBackwardItem, index: fastBackwardIndex } = useMemo(() => {
      const index = focusedOn - 2;
      // check whether that index exists
      if (index <= 0) return { item: null, index: 1 };
      const item = dedupedItems[index];
      return { item, index };
   }, [dedupedItems, focusedOn]);

   const { item: backwardItem, index: backwardIndex } = useMemo(() => {
      const index = focusedOn - 1;
      if (index <= 0) return { item: null, index: 1 };
      const item = dedupedItems[index];
      return { item, index };
   }, [dedupedItems, focusedOn]);
   const { item: forwardItem, index: forwardIndex } = useMemo(() => {
      const index = focusedOn + 1;
      if (index <= 0) return { item: null, index: 1 };
      const item = dedupedItems[index];
      return { item, index };
   }, [dedupedItems, focusedOn]);

   const { item: fastForwardItem, index: fastForwardIndex } = useMemo(() => {
      const index = focusedOn + 2;
      if (index <= 0) return { item: null, index: 1 };
      const item = dedupedItems[index];
      return { item, index };
   }, [dedupedItems, focusedOn]);

   useEffect(() => {
      const newIndex = dedupedItems.findIndex((item) => item.commentThreadId === focus_comment_thread_id);
      const id = dedupedItems[focusedOn]?.commentThreadId;
      if (newIndex === -1 || newIndex === 0) {
      } else if (newIndex !== focusedOn) {
         ILog.v("MagnifyingGlass_settingFocusedOn", { id, newIndex, dedupedItems });
         setQueryParams({ focusedOn: newIndex });
      }
      if (!focus_comment_thread_id) {
         ILog.v("MagnifyingGlass_settingCommentThreadId", { id, newIndex, dedupedItems });
         setQueryParams({ focus_comment_thread_id: dedupedItems[1]?.attributes.focusItemId || null });
      }
   }, [focusedOn, dedupedItems, focus_comment_thread_id]);
   ILog.v("MG_FINAL", {
      dedupedItems,
      token,
      editorDocumentId,
      focus_comment_thread_id,
      focus_item_id,
      focusedOn,
      toolbarEditor,
      revalidate,
      fastBackwardItem,
      backwardItem,
      forwardItem,
      fastForwardItem
   });
   return (
      <ToggleRightNav
         bottomSlot={
            <div ref={containerRef} className={`flex flex-col h-full `}>
               <_Stack flexBehavior={"row"} alignment={"between"} padding={"sm"}>
                  <_Stack flexBehavior={"row"}>
                     <_Button
                        testId={""}
                        disabled={!fastBackwardItem}
                        onButtonClick={() => {
                           if (!fastBackwardItem) return;

                           setQueryParams({ focus_comment_thread_id: fastBackwardItem.commentThreadId, focusedOn: fastBackwardIndex });
                        }}
                     >
                        <KeyboardDoubleArrowLeftIcon />
                     </_Button>
                     <_Button
                        testId={""}
                        disabled={!backwardItem}
                        onButtonClick={() => {
                           if (!backwardItem) return;
                           setQueryParams({ focus_comment_thread_id: backwardItem.commentThreadId, focusedOn: backwardIndex });
                        }}
                     >
                        <KeyboardArrowLeftIcon />
                     </_Button>
                  </_Stack>

                  <_Stack flexBehavior={"row"} alignment={"end"}>
                     <_Button
                        testId={""}
                        disabled={!forwardItem}
                        onButtonClick={() => {
                           if (!forwardItem) return;
                           setQueryParams({ focus_comment_thread_id: forwardItem.commentThreadId, focusedOn: forwardIndex });
                        }}
                     >
                        <KeyboardArrowRightIcon />
                     </_Button>
                     <_Button
                        testId={""}
                        disabled={!fastForwardItem}
                        onButtonClick={() => {
                           if (!fastForwardItem) return;
                           setQueryParams({ focus_comment_thread_id: fastForwardItem.commentThreadId, focusedOn: fastForwardIndex });
                        }}
                     >
                        <KeyboardDoubleArrowRightIcon />
                     </_Button>
                  </_Stack>
               </_Stack>
               {toolbarEditor && editorDocumentId && (
                  <_Stack padding="sm" className=" bg-slate-100 rounded-md w-full text-wrap">
                     {!focusedItem && <Header level="h5" label={"No comment thread or link selected."} />}
                     {dedupedItems.length > 0 &&
                        dedupedItems.map((item, index) => {
                           return (
                              <_DivUNSAFE key={index + item.commentThreadId + "CommentThreaddiv"} className={`${item.commentThreadId === focus_comment_thread_id ? "" : "absolute right-[100vw]"}`}>
                                 <FocusItem
                                    item={item}
                                    key={item?.commentThreadId}
                                    editor={toolbarEditor}
                                    {...focusedItem}
                                    id={item.commentThreadId}
                                    documentId={editorDocumentId}
                                    editable={editable}
                                    token={token}
                                    role={role}
                                    attrs={item.attributes}
                                 />
                              </_DivUNSAFE>
                           );
                        })}
                  </_Stack>
               )}
            </div>
         }
      />
   );
}

export type LinkItemProps = {
   id: string | null | undefined;
   editable: boolean;
   editor: Editor | null;
   documentId: string | null | undefined;
   token?: string;
   role: PA.RoleQueries | undefined;
   attrs: FocusItemMarkAttributes;
   item: FocusItemProps;
};

function FocusItem(props: LinkItemProps) {
   const { id, editor, documentId, editable, token, role, attrs, item } = props;
   const { href, type, base64 } = attrs;
   ILog.v("FocusItem", { token, href, base64 });
   return (
      <_Stack padding="sm" className=" bg-slate-100 rounded-md w-full text-wrap">
         {type === "link" && <LinkItem id={id} editor={editor} documentId={documentId} editable={editable} token={token} role={role} attrs={attrs} item={item} />}
         {type === "comment" && <CommentThread documentId={documentId} editable={editable} editor={editor} id={id} token={token} role={role} attrs={attrs} item={item} />}
      </_Stack>
   );
}

// type LinkFocusProps = {
//    editable: boolean;
//    editor: Editor | null;
//    focusedItemId: string | null | undefined;
//    documentId: string | null | undefined;
// };

// function LinkFocus({ documentId, editor, focusedItemId, editable }: LinkFocusProps) {
//    const els = document.querySelectorAll(".focus-item");

//    const dedupedItems = useMemo(() => {
//       const normalizedItems = Array.from(els).map((el) => {
//          const { top, left, width, height } = el.getBoundingClientRect();
//          const id = el.attributes.getNamedItem("data-focus-item-id")?.value;
//          if (!id) throw new Error("data-focus-item-id is required");

//          return { top, left, width, height, id, el };
//       });

//       const dedupedItems = normalizedItems.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index);
//       return dedupedItems;
//    }, [els]);

//    const focusedItem = useMemo(() => {
//       return dedupedItems.find((item) => item.id === focusedItemId);
//    }, [dedupedItems]);

//    return (
//       <_DivUNSAFE id={`link-focus`} className="absolute  z-50 hidden transition-all ease-linear">
//          <FocusItem key={focusedItem?.id} editor={editor} id={focusedItem?.id} documentId={documentId} editable={editable} el={focusedItem?.el} />
//       </_DivUNSAFE>
//    );
// }
MagnifyingGlass.Item = FocusItem;
// MagnifyingGlass.LinkFocus = LinkFocus;

export default MagnifyingGlass;
