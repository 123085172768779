import Placeholder from "@tiptap/extension-placeholder";
import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import { CampaignContext } from "app/(platform)/_contexts/CampaignContext";
import UserCard from "app/(platform)/profile/_components/UserCard";
import LoadingPageDefault from "app/_components_v2/feedback/LoadingPageDefault";
import DefaultXIcon from "app/_components_v2/icons/DefaultXIcon";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { VariantProps, cva } from "class-variance-authority";
import { useParams } from "next/navigation";
import { useContext, useState } from "react";
import { v4 } from "uuid";
import * as Y from "yjs";
import { apiChatMessage } from "../_api/chatMessage";
import { apiDocument } from "../_api/document";
import { ComponentEditor } from "./ComponentEditor";
import { LinkItemProps } from "./MagnifyingGlass";
import { IgnoreEnter } from "./plugins/IgnoreEnter";

type ChatProps = {} & VariantProps<typeof ChatVariants> & LinkItemProps;

const ChatVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function CommentThread({ id, item, editor, token, role, attrs, editable, ...rest }: ChatProps) {
   const { campaignId } = useContext(CampaignContext);
   const { userId } = useContext(UserIdContext);
   const attemptId = useParams().attempt_id as string;
   const templateId = useParams().template_id as string;
   const editionId = useParams().edition_id as string;
   const { data: commentsData } = apiChatMessage.useGetManyChatMessageWSQuery(
      {
         query: {
            sort: ["date_created"],
            filter: {
               chat_message_chat: {
                  _eq: item.commentThreadId
               }
            },
            //@ts-expect-error
            fields: ["*,chat_message_document.*"]
         },
         token,
         role
      },
      { skip: !role || !item.commentThreadId }
   );

   const [addComment] = apiChatMessage.useCreateChatMessageMutation();
   const [virtualDocumentId, setVirtualDocumentId] = useState<string>(v4());
   ILog.v("CommentThread", { editor, token, threadId: item.commentThreadId, attrs });
   if (!commentsData || !item.commentThreadId) return <LoadingPageDefault />;
   return (
      <_Stack>
         {editor && id && editable && (
            <_Button
               testId={`resolve-thread-button`}
               variant={"primary"}
               placement={"start"}
               onButtonClick={() => {
                  if (!id || !editor) throw new Error("No id or editor");
                  ILog.v("resolve-thread-button", { id, editor });
                  editor?.commands.unsetFocusItem(id);
                  //   toggleShowFocusItem({ show: false, clientX: 0, clientY: 0 });
               }}
            >
               Archive Thread
            </_Button>
         )}
         {commentsData.map((comment) => {
            if (!comment.chat_message_document || comment.chat_message_document.length === 0) return null;
            if (!comment.chat_message_document[0].document_base64_buffer) return null;
            return (
               <Comment
                  item={item}
                  {...rest}
                  editable={editable}
                  attrs={attrs}
                  key={comment.id}
                  token={token}
                  documentId={comment.chat_message_document[0]?.id}
                  editor={editor}
                  comment={comment}
                  role={role}
                  id={id}
                  document_base64_buffer={comment.chat_message_document[0].document_base64_buffer}
               />
            );
         })}

         {editable && (
            <_Stack className={`bg-white rounded`}>
               <ComponentEditor
                  addTopPriorityExtensions={[IgnoreEnter, Placeholder.configure({ placeholder: "Type your message here..." })]}
                  saveOnEnterCancelOnEscape={true}
                  editorClassAttributes={`editor editor-content h-fit max-w-[100%] mx-auto focus:outline-hidden border-black border-2 p-2`}
                  role={undefined}
                  token={undefined}
                  documentId={virtualDocumentId}
                  testId={""}
                  editable={true}
                  shouldConnectHocus={false}
                  shouldSetEditor={false}
                  shouldSetEditorOnCreate={false}
                  saveContentVersion={true}
                  contentOverride={null}
                  callToAction="Send"
                  callToCancel="Clear"
                  defaultDirty={true}
                  includeCommandItems={false}
                  expectAuthentication={false}
                  collaboratorName={undefined}
                  onFocusProvider={(provider) => {}}
                  onSubmit={(yDoc, editorInstance, provider) => {
                     ILog.v("onSubmit", { yDoc });
                     addComment({
                        payload: {
                           chat_message_document: [],
                           chat_message_chat: item.commentThreadId,
                           chat_message_campaign: campaignId,
                           chat_message_directus_users: userId,
                           status: "published"
                        },
                        document_activity_attempt: attemptId || null,
                        document_activity_version: templateId || null,
                        document_campaign_edition: editionId || null,
                        document_base64_buffer: Buffer.from(Y.encodeStateAsUpdate(yDoc)).toString("base64"),
                        token,
                        role
                     });
                     setVirtualDocumentId(v4());
                     provider?.destroy();
                     editorInstance.destroy();
                  }}
               />
            </_Stack>
         )}
      </_Stack>
   );
}

type CommentProps = {
   comment: M.ChatMessage;
   document_base64_buffer: string;
} & VariantProps<typeof CommentVariants> &
   ChatProps;

const CommentVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function Comment({ token, documentId, comment, role, item, document_base64_buffer }: CommentProps) {
   const { userId } = useContext(UserIdContext);
   const [deleteComment] = apiChatMessage.useDeleteChatMessageByIdMutation();
   const [updateDocument] = apiDocument.useUpdateDocumentByIdMutation();
   const { campaignId } = useContext(CampaignContext);
   const attemptId = useParams().attempt_id as string;
   const templateId = useParams().template_id as string;
   const editionId = useParams().edition_id as string;
   const [virtualDocumentId, setVirtualDocumentId] = useState<string>(v4());
   const [virtualBase64Buffer, setVirtualBase64Buffer] = useState<string>(document_base64_buffer);
   const isLocal = comment.chat_message_directus_users === userId;
   ILog.v("Comment", { comment, isLocal, userId });
   if (!virtualDocumentId || !documentId) return null;
   return (
      <_Stack alignment={"start"}>
         <_Stack flexBehavior={"row"} alignment={"between"}>
            <UserCard userId={comment.chat_message_directus_users as string | undefined} size={"sm"} />
            {isLocal && (
               <_Button
                  testId={`delete-comment-button`}
                  onButtonClick={() => {
                     deleteComment({ id: comment.id, token, role });
                  }}
                  disabled={false}
               >
                  <DefaultXIcon />
               </_Button>
            )}
         </_Stack>
         <_DivUNSAFE className={`${isLocal ? "bg-white" : ""} `}>
            <ComponentEditor
               addTopPriorityExtensions={[]}
               includeCommandItems={false}
               editorClassAttributes={`editor editor-content h-fit max-w-[100%] mx-auto ${isLocal ? "focus:outline-hidden focus:border-black border-2" : ""} p-2`}
               contentOverride={virtualBase64Buffer}
               role={undefined}
               token={undefined}
               documentId={virtualDocumentId}
               testId={""}
               editable={isLocal}
               shouldConnectHocus={false}
               shouldSetEditor={false}
               shouldSetEditorOnCreate={false}
               saveContentVersion={true}
               callToAction="Save"
               expectAuthentication={false}
               collaboratorName={undefined}
               defaultDirty={false}
               onFocusProvider={(provider) => {}}
               onSubmit={(yDoc, editorInstance, provider) => {
                  const document_base64_buffer = Buffer.from(Y.encodeStateAsUpdate(yDoc)).toString("base64");
                  ILog.v("onSubmit", { yDoc, document_base64_buffer });
                  updateDocument({
                     id: documentId,
                     payload: {
                        document_base64_buffer
                     },
                     token,
                     role
                  });
                  setVirtualBase64Buffer(document_base64_buffer);
                  setVirtualDocumentId(v4());
                  provider?.destroy();
                  editorInstance.destroy();
               }}
            />
         </_DivUNSAFE>
      </_Stack>
   );
}

export { Comment, CommentThread };
