"use client";
import _Stack from "app/_components_v2/layout/_Stack";
import React from "react";
import { useLocalStorage } from "usehooks-ts";

interface ToggleRightNavProps {
   bottomSlot: React.ReactNode;
}
export default function ToggleRightNav(props: ToggleRightNavProps) {
   const [open, setOpen] = useLocalStorage("toggle-right-nav", false);

   return (
      <_Stack flexBehavior={"row"} gap={"none"} fluid={false}>
         <button onClick={() => setOpen(!open)} className={" bg-slate-300 hover:bg-slate-400 w-3 hover:cursor-pointer rounded-tl-md rounded-bl-md "} />
         <div className={` ${open ? "min-w-[30vw]" : "hidden"} overflow-x-hidden m-1`}>{props.bottomSlot}</div>
      </_Stack>
   );
}
