import { Query } from "@directus/sdk";
import { current } from "@reduxjs/toolkit";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { apiWS } from "app/(platform)/@search/_api/apiWS";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { handleRTKError, handleRTKTags } from "../../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../../_services/redux/api/apiService";
import { apiDocument } from "./document";

export const apiChatMessage = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getManyChatMessageWS: builder.query<
         M.ChatMessage[],
         {
            query: Query<M.CustomDirectusTypes, M.ChatMessage[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error("no token, roleId, or role found");

               const res = (await api
                  .dispatch(
                     apiWS.endpoints.emitWSMessage.initiate({
                        role: arg.role,
                        message: {
                           type: "items",
                           action: "read",
                           collection: "chat_message",
                           query: arg.query
                        }
                     })
                  )
                  .unwrap()) as M.ChatMessage[] | null;

               if (!res) throw new Error("No data found");
               ILog.v("getManyChatMessageWS", { res });

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "apiChatMessage_getManyWS" });
            }
         },
         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, dispatch, extra }) {
            await tryDirectusWS<M.ChatMessage>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "chat_message",
               query: arg.query,
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;
                  if (event === "init" && data?.length > 0) {
                     // updateCachedData((draft) => {
                     //    ILog.v("updateCachedData init document", { data, draft: current(draft) });
                     //    return data;
                     // });
                  } else if (event === "create") {
                     updateCachedData((draft) => {
                        // const updated = data;

                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        ILog.v("updateCachedData create", { data, draft: current(draft), entries });
                        entries?.push(...data);
                        return entries;
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        entries?.push(...data);
                        ILog.v("updateCachedData update", { entries, data, draft: current(draft) });
                        return entries;
                        // draft?.filter((d) => !data.some((u) => u.id === d.id));
                        // //@ts-expect-error
                        // return draft?.push(...data);
                        // // return entries;
                     });
                  } else if (event === "delete") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((r) => !(data as unknown as string[]).includes(r?.id!));
                        ILog.v("updateCachedData delete", { data, draft: current(draft) });
                        return entries;
                        // ILog.v("updateCachedData delete-FINAL", { data, draft: current(draft) });
                     });
                  }
               },
               uidArgs: "",
               cacheEntryRemoved
            });
         },

         providesTags: (result, error, query) => {
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CHAT_MESSAGE, includeList: true }]
            });
         }
      }),
      getOneChatMessage: builder.query<
         M.ChatMessage | null,
         {
            query: Query<M.CustomDirectusTypes, M.ChatMessage[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.ChatMessageService.getOne({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getOneChatMessage" });
            }
         },

         providesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && [{ type: TAGS.CHAT_MESSAGE, id: result.id }] })
      }),
      getManyChatMessage: builder.query<
         M.ChatMessage[],
         {
            query: Query<M.CustomDirectusTypes, M.ChatMessage[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.ChatMessageService.getMany({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyChatMessage" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && result.map((r) => ({ type: TAGS.CHAT_MESSAGE, id: r.id })) })
      }),
      updateChatMessageById: builder.mutation<M.ChatMessage, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.ChatMessage> }>({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.ChatMessageService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateChatMessageById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && [{ type: TAGS.CHAT_MESSAGE, id: result.id }] })
      }),
      deleteChatMessageById: builder.mutation<null, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string }>({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.ChatMessageService.deleteById({ token, id: arg.id });
               return { data: null };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "deleteChatMessageById" });
            }
         },
         invalidatesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: [{ type: TAGS.CHAT_MESSAGE, id: "LIST" }] })
      }),
      createChatMessage: builder.mutation<
         M.ChatMessage,
         {
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            payload: M.IOmit<M.ChatMessage>;
            document_activity_attempt: string | null;
            document_activity_version: string | null;
            document_base64_buffer: string | null;
            document_campaign_edition: string | null;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);

               const chatMessage = await REST.ChatMessageService.create({ token: token!, payload: arg.payload });
               const chatDocument = await api
                  .dispatch(
                     apiDocument.endpoints.createDocument.initiate({
                        role: undefined,
                        token,
                        payload: {
                           document_campaign: arg.payload.chat_message_campaign,
                           document_folder: null,

                           status: "anonymous",
                           document_section_document_edit: [],
                           document_chat_message: chatMessage.id,
                           document_activity_attempt: arg.document_activity_attempt,
                           document_activity_version: arg.document_activity_version,
                           document_base64_buffer: arg.document_base64_buffer,
                           document_campaign_edition: arg.document_campaign_edition
                        }
                     })
                  )
                  .unwrap();
               await REST.ChatMessageService.updateById({ token: token!, id: chatMessage.id, payload: { chat_message_document: [chatDocument.id] } });
               // const res = await REST.ChatMessageService.create({ token: token!, payload: { ...arg.payload, chat_message_document: [chatDocument.id] } });
               const get = await REST.ChatMessageService.getOne({ token: token!, id: chatDocument.document_chat_message as string });
               const res = get;
               if (!res) throw new Error("No data found");
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createChatMessage" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result && [{ type: TAGS.CHAT_MESSAGE, id: result.id }],
               tagSelectors: [
                  { collectionTag: TAGS.CHAT_MESSAGE, includeList: true },
                  { collectionTag: TAGS.DOCUMENT, includeList: true, idKey: "chat_message_document", keyIsArray: true },
                  { collectionTag: TAGS.CHAT, includeList: true, idKey: "chat_message_chat" }
               ]
            })
      })
   }),
   overrideExisting: true
});
