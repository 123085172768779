import { HocuspocusProvider } from "@hocuspocus/provider";
import { Extension } from "@tiptap/core";
import { Editor, EditorContent } from "@tiptap/react";
import { apiAnySession } from "app/(platform)/(authentication)/_api/anySession";
import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import _Button from "app/_components_v2/inputs/_Button";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import * as Y from "yjs";
import { ToolbarToggleSetterContext } from "../_contexts/ToolbarProvider";
import useTiptapEditor from "../_hooks/useTiptapEditor";

export interface ComponentEditorProps {
   token?: string | undefined;
   role: PA.RoleQueries | undefined;
   documentId: string;
   testId: string;
   editable: boolean;
   shouldConnectHocus: boolean;
   shouldSetEditor?: boolean;
   saveContentVersion: boolean;
   contentOverride: string | null | undefined;
   expectAuthentication: boolean;
   callToAction?: string;
   callToCancel?: string;
   onSubmit?: (ydoc: Y.Doc, editor: Editor, provider: HocuspocusProvider | null, createEditor: () => void) => void;
   defaultDirty?: boolean;
   editorClassAttributes?: string;
   includeCommandItems?: boolean;
   onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>, setDirty: Dispatch<SetStateAction<boolean>>) => void;
   saveOnEnterCancelOnEscape?: boolean;
   addTopPriorityExtensions: Extension[];
   collaboratorName: string | undefined;
   shouldSetEditorOnCreate?: boolean;
   onFocusProvider: (provider: HocuspocusProvider) => void;
}
export const ComponentEditor = (props: ComponentEditorProps) => {
   const { role, token, ...rest } = props;
   const { data: session } = apiAnySession.useGetSessionQuery({ role }, { skip: !role || !!token });
   const finalToken = token || session?.token;

   ILog.v("ComponentEditor", { token: session?.token, tokenProp: token, role, ...rest });

   if (!props.expectAuthentication) {
      return <IComponentEditor {...rest} token={undefined} role={undefined} />;
   }
   if (!!finalToken) {
      return <IComponentEditor {...rest} token={finalToken} role={role} />;
   }
   return null;
};

export interface IComponentEditorProps extends ComponentEditorProps {
   token: string | undefined;
}
const IComponentEditor = ({
   documentId,
   testId,
   token,
   editable,
   shouldConnectHocus,
   shouldSetEditor = true,
   saveContentVersion,
   expectAuthentication = true,
   includeCommandItems = true,
   onSubmit,
   ...props
}: IComponentEditorProps) => {
   const { invalidateToolbarState } = useContext(ToolbarToggleSetterContext);
   const [isPortrait] = useLocalStorage("orientation", true);
   const [dirty, setDirty] = useState(!!props.defaultDirty || false);
   const { userId } = useContext(UserIdContext);

   const { editorInstance, provider, yDoc, createEditor } = useTiptapEditor({
      documentId,
      expectAuthentication,
      token,
      editable,
      autoFocus: false,
      shouldConnectHocus,
      shouldSetEditor,
      saveContentVersion,
      includeCommandItems,
      userId,
      ...props
   });

   function onCancel() {
      if (!props.defaultDirty) {
         setDirty(false);
      }
      if (editorInstance) {
         editorInstance.destroy();
      }
      if (provider) {
         provider.destroy();
      }
      createEditor();
   }
   ILog.v("ComponentEditor1", { editorInstance, documentId, testId, editable, ...props });
   if (editorInstance && yDoc) {
      //@ts-expect-error
      const instanceId = editorInstance?.options?.editorProps?.attributes?.instanceId;
      ILog.v("ComponentEditor2", { isEditable: editorInstance.isEditable, ...props });
      return (
         <_Stack gap="none" className={` ${isPortrait ? "" : ""}`}>
            <EditorContent
               data-testid={testId}
               data-editor-editable={editorInstance.isEditable}
               className={`${isPortrait ? "" : ""} `}
               key={`${instanceId}-component-editor-content`}
               editor={editorInstance}
               autoFocus={false}
               // readOnly={!editable}
               // contentEditable={editable}
               onClick={() => {
                  invalidateToolbarState();
               }}
               onChange={() => {}}
               onKeyDown={(e) => {
                  !!editable && setDirty(true);
                  if (props.saveOnEnterCancelOnEscape) {
                     if (e.key === "Enter") {
                        if (e.shiftKey) {
                           return;
                        }
                        editorInstance.commands.blur();
                        if (yDoc && onSubmit) {
                           if (!props.defaultDirty) {
                              setDirty(false);
                           }
                           onSubmit(yDoc, editorInstance, provider, createEditor);
                        }
                     } else if (e.key === "Escape") {
                        editorInstance.commands.blur();
                        onCancel();
                     }
                  }
                  // if (props.onKeyDown) {
                  //    props.onKeyDown(e, setDirty);
                  // }
               }}
               onInput={() => {
                  !!editable && setDirty(true);
               }}
               onDrop={(props) => {
                  ILog.v("EditorContent_onDrop_ComponentEditor", { props });
               }}
               onPaste={(e) => {
                  ILog.v("EditorContent_onPaste_ComponentEditor", { e });
               }}
            />
            {dirty && saveContentVersion && !!onSubmit && (
               <_Stack flexBehavior={"row"} className="p-2" alignment={"end"}>
                  <_Button
                     testId=""
                     onButtonClick={() => {
                        onCancel();
                     }}
                  >
                     {props.callToCancel || "Cancel"}
                  </_Button>
                  <_Button
                     testId=""
                     variant={"primary"}
                     onButtonClick={() => {
                        if (!props.defaultDirty) {
                           setDirty(false);
                        }
                        onSubmit(yDoc, editorInstance, provider, createEditor);
                     }}
                  >
                     {props.callToAction || "Save"}
                  </_Button>
               </_Stack>
            )}
         </_Stack>
      );
   }
   ILog.v("ComponentEditor3", { editorInstance, documentId, testId, editable, ...props });
   return null;
};
