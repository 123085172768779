import { VariantProps, cva } from "class-variance-authority";

type DefaultXIconProps = { className?: string } & VariantProps<typeof DefaultXIconVariants>;

const DefaultXIconVariants = cva("", {
   variants: {
      variant: {
         primary: ""
      }
   },
   compoundVariants: [
      {
         variant: [],
         class: ""
      }
   ],
   defaultVariants: {}
});

function DefaultXIcon({ className }: DefaultXIconProps) {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${className || ""}`}>
         <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
   );
}

export default DefaultXIcon;
