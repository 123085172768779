import { Editor } from "@tiptap/core";
import ILog from "app/_lib/global/Log";
import { Dispatch, SetStateAction, createContext, useCallback, useState } from "react";
import { useLocalStorage, useToggle } from "usehooks-ts";

interface ToolbarProviderProps {
   children: React.ReactNode;
}
export default function ToolbarProvider({ children }: ToolbarProviderProps) {
   const [value, toggle, setValue] = useToggle();
   const { toolbarEditor, setToolbarEditor, setViewSettings, viewSettings, droppedNodes, setDroppedNodes, editorDocumentId, setEditorDocumentId } = useToolbarCallbacks({});
   ILog.v("ToolbarProvider", {});
   return (
      <EditorDocumentIdSetterContext.Provider value={{ setEditorDocumentId }}>
         <EditorDocumentIdContext.Provider value={{ editorDocumentId }}>
            <EditorDroppedNodesContext.Provider value={{ droppedNodes }}>
               <EditorDroppedNodesSetterContext.Provider value={{ setDroppedNodes }}>
                  <ToolbarEditorContext.Provider value={{ toolbarEditor }}>
                     <ToolbarViewSettingsContext.Provider value={{ viewSettings, setViewSettings }}>
                        <ToolbarSetterContext.Provider value={{ setToolbarEditor }}>
                           <ToolbarToggleSetterContext.Provider value={{ invalidateToolbarState: toggle }}>{children}</ToolbarToggleSetterContext.Provider>
                        </ToolbarSetterContext.Provider>
                     </ToolbarViewSettingsContext.Provider>
                  </ToolbarEditorContext.Provider>
               </EditorDroppedNodesSetterContext.Provider>
            </EditorDroppedNodesContext.Provider>
         </EditorDocumentIdContext.Provider>
      </EditorDocumentIdSetterContext.Provider>
   );
}

export interface DroppedNode {
   id: string;
   newDocumentId: string;
}

export type EditorDroppedNodesSetterContextType = {
   setDroppedNodes: Dispatch<SetStateAction<DroppedNode[]>>;
};

export const EditorDroppedNodesSetterContext = createContext<EditorDroppedNodesSetterContextType>({
   setDroppedNodes: () => {}
});

export type EditorDroppedNodesContextType = {
   droppedNodes: DroppedNode[];
};

export const EditorDroppedNodesContext = createContext<EditorDroppedNodesContextType>({
   droppedNodes: [{ id: "ERROR", newDocumentId: "ERROR" }]
});

export type EditorDocumentIdSetterContextType = {
   setEditorDocumentId: (documentId: string | null) => void;
};

export const EditorDocumentIdSetterContext = createContext<EditorDocumentIdSetterContextType>({
   setEditorDocumentId: () => {}
});

export type EditorDocumentIdContextType = {
   editorDocumentId: string | null;
};

export const EditorDocumentIdContext = createContext<EditorDocumentIdContextType>({
   editorDocumentId: null
});

export type ToolbarSetterContextType = {
   setToolbarEditor: (editor: Editor | null) => void;
};

export const ToolbarSetterContext = createContext<ToolbarSetterContextType>({
   setToolbarEditor: () => {}
});

export type ToolbarEditorContextType = {
   toolbarEditor: Editor | null;
};

export const ToolbarEditorContext = createContext<ToolbarEditorContextType>({
   toolbarEditor: null
});

// export type OrientationSetterContextType = {
//    setOrientation: (isProfile: boolean) => void;
// };

// export const PortraitLandScapeSetterContext = createContext<OrientationSetterContextType>({
//    setOrientation: () => {}
// });

// export type OrientationContextType = {
//    isPortrait: boolean;
// };

// export const OrientationContext = createContext<OrientationContextType>({
//    isPortrait: true
// });

export type ToolbarInvalidationContextType = {
   invalidateToolbarState: () => void;
};

export const ToolbarToggleSetterContext = createContext<ToolbarInvalidationContextType>({
   invalidateToolbarState: () => {}
});

type StepViewSettings = "inline" | "side" | "lean";

export type ToolbarViewSettingsContextType = {
   viewSettings: { steps: StepViewSettings };
   setViewSettings: (settings: { steps: StepViewSettings }) => void;
};

export const ToolbarViewSettingsContext = createContext<ToolbarViewSettingsContextType>({
   viewSettings: { steps: "lean" },
   setViewSettings: () => {}
});

export function useToolbarCallbacks({}) {
   const [toolbarEditor, set] = useState<Editor | null>(null);
   const [viewSettings, setView] = useLocalStorage<{ steps: StepViewSettings }>("editor-view-settings", { steps: "lean" });
   const [droppedNodes, setDroppedNodes] = useState<DroppedNode[]>([]);
   const [editorDocumentId, setDocumentId] = useState<string | null>(null);
   const setToolbarEditor = useCallback(
      (editor: Editor | null) => {
         if (!!editor) {
            set(editor);
         }
      },
      [set]
   );
   const setViewSettings = useCallback(
      (settings: typeof viewSettings) => {
         setView(settings);
      },
      [setView]
   );
   const setEditorDocumentId = useCallback(
      (id: string | null) => {
         setDocumentId(id);
      },
      [setDocumentId]
   );

   return { toolbarEditor, setToolbarEditor, viewSettings, setViewSettings, droppedNodes, setDroppedNodes, editorDocumentId, setEditorDocumentId };
}
