import { Query } from "@directus/sdk";
import { current } from "@reduxjs/toolkit";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { apiWS } from "app/(platform)/@search/_api/apiWS";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { TAGS } from "../../../../../_services/redux/api/TAGS";
import { handleRTKError, handleRTKTags } from "../../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../../_services/redux/api/apiService";
export const apiDocument = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getManyDocumentWS: builder.query<
         M.Document[],
         {
            query: Query<M.CustomDirectusTypes, M.Document[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error("no token, roleId, or role found");

               const res = (await api
                  .dispatch(
                     apiWS.endpoints.emitWSMessage.initiate({
                        role: arg.role,
                        message: {
                           type: "items",
                           action: "read",
                           collection: "document",
                           query: arg.query
                        }
                     })
                  )
                  .unwrap()) as M.Document[] | null;

               if (!res) throw new Error("No data found");

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "apiDocument_getManyWS" });
            }
         },
         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, dispatch, extra }) {
            await tryDirectusWS<M.Document>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "document",
               query: arg.query,
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;
                  if (event === "init" && data?.length > 0) {
                     // updateCachedData((draft) => {
                     //    ILog.v("updateCachedData init document", { data, draft: current(draft) });
                     //    return data;
                     // });
                  } else if (event === "create") {
                     updateCachedData((draft) => {
                        // const updated = data;

                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        ILog.v("updateCachedData create", { data, draft: current(draft), entries });
                        entries?.push(...data);
                        return entries;
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        entries?.push(...data);
                        ILog.v("updateCachedData update", { entries, data, draft: current(draft) });
                        return entries;
                        // draft?.filter((d) => !data.some((u) => u.id === d.id));
                        // //@ts-expect-error
                        // return draft?.push(...data);
                        // // return entries;
                     });
                  } else if (event === "delete") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((r) => !(data as unknown as string[]).includes(r?.id!));
                        ILog.v("updateCachedData delete", { data, draft: current(draft) });
                        return entries;
                        // ILog.v("updateCachedData delete-FINAL", { data, draft: current(draft) });
                     });
                  }
               },
               uidArgs: "",
               cacheEntryRemoved
            });
         },

         providesTags: (result, error, query) => {
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: true }]
            });
         }
      }),
      getOneDocument: builder.query<
         M.Document | null,
         {
            query?: Query<M.CustomDirectusTypes, M.Document[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });

               const res = await REST.DocumentService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "apiDocument_getOne" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: true }]
            })
      }),
      getManyDocument: builder.query<
         M.Document[],
         {
            query: Query<M.CustomDirectusTypes, M.Document[]> | undefined;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.DocumentService.getMany({
                  token: token,
                  query: arg.query!
               });
               if (!res) throw new Error(TAGS.UNKNOWN_ERROR);
               ILog.v("getManyDocument", { data: res });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyDocument" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: false }] })
      }),
      getManyDocumentLoop: builder.query<
         M.Document[],
         {
            query: Query<M.CustomDirectusTypes, M.Document[]>;
            roles: PA.RoleQueries[] | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               if (!arg.roles) throw new Error("Roles not provided");
               let data = [] as M.Document[];
               for (const role of arg.roles) {
                  const { token } = await getMySession({ role: role, tokenOverride: arg.token, dispatch: api.dispatch });
                  const res = await REST.DocumentService.getMany({
                     token: token,
                     query: arg.query
                  });
                  if (res && res.length > 0) data = [...data, ...res];
               }

               return { data };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyDocumentLoop" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: true }]
            })
      }),
      updateDocumentById: builder.mutation<M.Document, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.Document> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.DocumentService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateDocumentById" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: false }] })
      }),
      createDocument: builder.mutation<M.Document, { role: PA.RoleQueries | undefined; token?: string | undefined; payload: Partial<M.Document> }>({
         queryFn: async (arg, api) => {
            try {
               const { token, authenticatedSession } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.DocumentService.create({ token: token!, payload: arg.payload });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createDocument" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.DOCUMENT, includeList: true }]
            })
      })
   }),
   overrideExisting: true
});
