import LinkOffIcon from "@mui/icons-material/LinkOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import _Paragraph from "app/_components_v2/data-display/Paragraph";
import _Button from "app/_components_v2/inputs/_Button";
import LeanInput from "app/_components_v2/inputs/LeanInput";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import Image from "next/image";
import Link from "next/link";
import { Fragment } from "react";
import { toggleShowFocusItem } from "../_contexts/FocusItemProvider";
import { LinkItemProps } from "./MagnifyingGlass";

export function LinkItem(props: LinkItemProps) {
   const { id, editor, documentId, editable, token, attrs } = props;
   const { href, type, title, base64 } = attrs;
   ILog.v("LinkItem", { token, href, base64 });
   if (type !== "link") return null;
   return (
      <Fragment>
         {editable && (
            <_Stack flexBehavior={"row"}>
               <LeanInput.Text
                  testId={``}
                  debounceInterval={1000}
                  spellCheck={false}
                  label={`URL`}
                  defaultValue={href}
                  onChange={(e) => {
                     if (!id || !editor) return;
                     if (documentId === null) {
                        ILog.w("LinkItem documentId is null");
                     }
                     let href = e.target.value;
                     // If it doesn't start with https:// then add it to the url
                     if (href.startsWith("www.")) {
                        href = `https://${href}`;
                     }
                     editor?.commands.setFocusItemsAttributes({ focusItemId: id, attributes: { ...attrs, href, type: "link" }, documentId: documentId || null, fetchLinkPreview: true });
                  }}
               />
               <_Button
                  testId={`remove-link-button`}
                  onButtonClick={() => {
                     if (!id || !editor) return;
                     editor?.commands.unsetFocusItem(id);
                     toggleShowFocusItem({ show: false, clientX: 0, clientY: 0 });
                  }}
               >
                  <LinkOffIcon className="-rotate-45" />
               </_Button>
               {href && (
                  <_Button
                     testId={`refresh-link-button`}
                     onButtonClick={() => {
                        if (!id || !editor || !href) return;
                        editor?.commands.setFocusItemsAttributes({ focusItemId: id, attributes: { ...attrs, href, type: "link" }, documentId: documentId || null, fetchLinkPreview: true });
                     }}
                  >
                     <RefreshIcon />
                  </_Button>
               )}
            </_Stack>
         )}
         {href && (
            <Link href={href} prefetch={false} passHref legacyBehavior>
               <a target="_blank">
                  {base64 && <Image src={`data:image/png;base64,${base64}`} alt={title || ""} width={500} height={300} />}
                  {/* {!base64 && <_Paragraph label={`No preview available.`} className="" wrap />} */}
                  <_Stack flexBehavior={"row"}>
                     <_Paragraph label={title || href} className="underline" wrap />
                     {/* Navigate Icon */}
                  </_Stack>
               </a>
            </Link>
         )}
      </Fragment>
   );
}
