import { Content, Editor, Range } from "@tiptap/core";
import { Extension, ReactRenderer } from "@tiptap/react";
import Suggestion, { SuggestionProps } from "@tiptap/suggestion";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import ILog from "app/_lib/global/Log";
import { useParams } from "next/navigation";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import tippy, { GetReferenceClientRect, Instance, Props } from "tippy.js";
import { v4 } from "uuid";
import useActivityPrefix from "../../activities/_hooks/useActivityPrefix";
import { AssetNodeAttributesInitialization } from "../nodes/defaultNodeAttributes";

interface CommandsViewProps extends SuggestionProps {}
const CommandsView = forwardRef((props: CommandsViewProps, ref) => {
   const {} = props;
   // const attrs = useContext(NodeAttrsContext);
   const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
   useEffect(() => {
      setSelectedIndex(0);
   }, [props.items]);

   useImperativeHandle(ref, () => ({
      onKeyDown: (event: KeyboardEvent) => {
         if (event.key === "ArrowUp") {
            upHandler();
            return true;
         }

         if (event.key === "ArrowDown") {
            downHandler();
            return true;
         }

         if (event.key === "Enter") {
            enterHandler();
            return true;
         }

         return false;
      }
   }));

   const upHandler = () => {
      setSelectedIndex(((selectedIndex || 0) + props.items.length - 1) % props.items.length);
   };

   const downHandler = () => {
      setSelectedIndex(selectedIndex === null ? 0 : ((selectedIndex || 0) + 1) % props.items.length);
   };

   const enterHandler = () => {
      selectItem(selectedIndex);
   };

   const selectItem = (index: number | null) => {
      let item = props.items[index || 0];
      ILog.v("selectItem", { index, item, props });
      item.props = { ...item.props };
      // item.props.context = { defaultAttributes: attrs };
      if (item) {
         props.command(item);
      }
   };

   return (
      <_DivUNSAFE
         id="parent" // "insert-menu"
         data-tippy-root
         className="flex flex-col gap-3 p-2 rounded-lg bg-slate-50 drop-shadow-lg"
      >
         {props.items.map((item, index) => {
            return (
               <button type="button" className={`${index === selectedIndex ? "active" : ""}`} {...item.attrs} key={index} onMouseDown={() => selectItem(index)}>
                  <_DivUNSAFE id="menu-wrapper" className="flex flex-row gap-3">
                     <_DivUNSAFE id="menu-title" className="menu-title text-slate-900">
                        {item.element || item.title}
                     </_DivUNSAFE>
                     <_DivUNSAFE id="menu-subtitle" className="menu-subtitle text-slate-900">
                        {item.subtitle}
                     </_DivUNSAFE>
                  </_DivUNSAFE>
               </button>
            );
         })}
      </_DivUNSAFE>
   );
});
CommandsView.displayName = "CommandsView";
export interface CommandProps {
   title: string;
   subtitle: string;
   command: (a: { editor: Editor; range: Range; props: any }) => void;
}
const config: Content = {
   type: "paragraph"
};

interface UseCommandsPluginProps {}

export function generateDefaultAssetNodeAttrs(): AssetNodeAttributesInitialization {
   return {
      assetNodeId: v4(),
      attemptId: undefined,
      campaignId: undefined,
      teamId: undefined,
      sectionId: undefined,
      section_section_asset: undefined,
      sectionDocumentId: undefined,
      templateId: undefined,
      attemptSpaceId: undefined,
      versionSpaceId: undefined,
      editionId: undefined,
      focusFile: false,
      focusImage: false,
      focusVideo: false,
      nodeType: "AssetNode",
      motionSafeSrc: undefined
   } satisfies AssetNodeAttributesInitialization;
}

export function useCommandsPlugin({ includeCommandItems }: { includeCommandItems?: boolean }) {
   const teamId = useParams().team_id as string;
   const templateId = useParams().template_id as string;
   const attemptId = useParams().attempt_id as string;
   const attemptSpaceId = useParams().attempt_space_id as string;
   const versionSpaceId = useParams().template_space_id as string;
   const documentId = useParams().document_id as string;
   const campaignId = useParams().campaign_id as string;
   const editionId = useParams().edition_id as string;

   const { activityPrefix, activityType } = useActivityPrefix({ teamId, templateId, attemptId });
   return useMemo(() => {
      const assetNodeAttrs = generateDefaultAssetNodeAttrs();

      let items = [
         // {
         //    title: "Import docx",
         //    subtitle: "Import contents of a .docx file",
         //    command: ({ editor, range }) => {
         //       editor
         //          .chain()
         //          .focus()
         //          .deleteRange(range)
         //          .insertContentAt(range.from, {
         //             type: "ImportDocXPlaceholder",
         //             attrs: { id: v4() }
         //          })
         //          .run();
         //    }
         // }
      ] as CommandProps[];

      // if (activityPrefix === "todo") {
      //    items.push({
      //       title: "Step",
      //       subtitle: "Insert a step",
      //       command: ({ editor, range, props }) => {
      //          // const selected = editor.state.selection;
      //          // const range = selected;
      //          const actvitySpecAttrs = {
      //             id: v4(),
      //             nodeType: "ActivitySpec",
      //             activityPrefix: activityPrefix,
      //             specType: activityType,
      //             instructionsContent: undefined,
      //             teamId,
      //             templateId,
      //             attemptId,
      //             campaignId,
      //             sectionRun: 1,
      //             attemptSpaceId,
      //             versionSpaceId,
      //             focusDeliverableTag: false,
      //             focusSection: false,
      //             deliverableContent: undefined,
      //             deliverableDocumentId: undefined,
      //             instructionsDocumentId: undefined,
      //             stepDocumentId: documentId,
      //             focusFile: false,
      //             focusImage: false,
      //             focusVideo: false,
      //             section_section_asset: undefined,
      //             sectionId: undefined,
      //             alignment: undefined,
      //             dimensions: undefined,
      //             editionId
      //          } satisfies ActivitySpecNodeAttributesInitialization;
      //          ILog.v("Toolbar_onButtonClick_mark-instructions", { attrs: actvitySpecAttrs });
      //          let content: Content = [
      //             {
      //                type: "ActivitySpec",
      //                attrs: actvitySpecAttrs
      //             }
      //          ];
      //          content.unshift(config);
      //          content.push(config);
      //          // const instructionsJsonContent = selected.content().toJSON()?.content?.[0];
      //          editor.chain().focus().deleteRange(range).insertContentAt(range.from, content, { applyPasteRules: false, applyInputRules: false, updateSelection: false }).run();
      //       }
      //    });
      // }

      if (includeCommandItems === true) {
         items.push(
            {
               title: "Image",
               // subtitle: "Insert an image",
               subtitle: "",
               command: ({ editor, range, props }) => {
                  const selection = editor.view.state.selection;
                  const from = selection.$from.posAtIndex(0);
                  const to = selection.$from.posAtIndex(1);

                  ILog.v("insert-image", { editor, range, props, attrs: assetNodeAttrs, selection });
                  let content: Content = [
                     {
                        type: "AssetNode",
                        attrs: { ...assetNodeAttrs, focusImage: true }
                     }
                  ];
                  // content.unshift(config);
                  // content.push(config);

                  editor.chain().focus().deleteRange(range).insertContentAt(range.from, content, { applyPasteRules: false, applyInputRules: false, updateSelection: false }).run();
               }
            },
            {
               title: "Video",
               // subtitle: "Insert a video",
               subtitle: "",

               command: ({ editor, range, props }) => {
                  ILog.v("insert-video", { editor, range, props });
                  let content: Content = [
                     {
                        type: "AssetNode",
                        attrs: { ...assetNodeAttrs, focusVideo: true }
                     }
                  ];
                  content.unshift(config);
                  content.push(config);
                  editor.chain().focus().deleteRange(range).insertContentAt(range.from, content, { applyPasteRules: false, applyInputRules: false, updateSelection: false }).run();
               }
            },
            {
               title: "File",
               // subtitle: "Upload a file",
               subtitle: "",

               command: ({ editor, range, props }) => {
                  ILog.v("fileUpload", { editor, range, props });
                  let content: Content = [
                     {
                        type: "AssetNode",
                        attrs: { ...assetNodeAttrs, focusFile: true }
                     }
                  ];
                  content.unshift(config);
                  content.push(config);
                  editor.chain().focus().deleteRange(range).insertContentAt(range.from, content, { applyPasteRules: false, applyInputRules: false, updateSelection: false }).run();
               }
            }
         );
      } else {
         items.push({
            title: "Commands not available here.",
            // subtitle: "Insert an image",
            subtitle: "",
            command: ({ editor, range, props }) => {}
         });
      }

      return Extension.create({
         name: "insertMenu",
         addProseMirrorPlugins() {
            return [
               Suggestion<CommandProps>({
                  editor: this.editor,
                  char: "/",
                  command: ({ editor, range, props }) => {
                     ILog.v("insertMenu", { editor, range, props });
                     props.command({ editor, range, props });
                  },
                  items: ({ query }) => {
                     return items.filter((item) => {
                        return item.title.toLowerCase().startsWith(query.toLowerCase());
                     });
                  },
                  startOfLine: true,
                  allowSpaces: true,
                  allow: ({ state, range, editor }) => {
                     const node = state.selection.$from.node();
                     if (!node) {
                        return false;
                     }
                     return node.textBetween(0, 1) === "/";
                  },
                  render: () => {
                     let component: ReactRenderer<any, any>;
                     let popup: Instance<Props> | undefined = undefined;

                     return {
                        onStart: (props) => {
                           component = new ReactRenderer(CommandsView, {
                              props,
                              editor: props.editor
                           });
                           popup = tippy(props.editor.options.element, {
                              getReferenceClientRect: props.clientRect as GetReferenceClientRect,
                              content: component.element,
                              showOnCreate: true,
                              interactive: true,
                              trigger: "manual",
                              placement: "bottom-start",
                              theme: "custom-none"
                           });
                        },
                        onUpdate: (props) => {
                           component?.updateProps(props);
                           const clientRect = props.clientRect;

                           let domRect: DOMRect | null = null;
                           if (typeof clientRect === "function") {
                              domRect = clientRect();
                           }

                           if (domRect !== null && popup !== undefined) {
                              // ILog.verbose( "tippyonUpdate", { clientRect, domRect, popup, props });
                              // popup?.setProps({
                              //    // getReferenceClientRect: props.clientRect
                              //    getReferenceClientRect: props.clientRect as GetReferenceClientRect
                              //    // getReferenceClientRect: () => domRect!
                              // });
                           }
                        },
                        onKeyDown: ({ event }) => {
                           if (event.key === "Escape") {
                              popup?.hide();
                              return true;
                           }
                           if (component?.ref?.onKeyDown) {
                              return component.ref.onKeyDown(event as KeyboardEvent);
                           } else {
                              return true;
                           }
                        },
                        onExit: () => {
                           if (!!component) {
                              try {
                                 component?.destroy();
                              } catch (e) {
                                 ILog.e("component_onExit", { e, component });
                              }
                           }
                           if (!!popup) {
                              try {
                                 popup?.destroy();
                              } catch (e) {
                                 ILog.e("popup_onExit", { e, popup });
                              }
                           }
                        }
                     };
                  }
               })
            ];
         }
      });
   }, [campaignId, teamId, templateId, attemptId, attemptSpaceId, versionSpaceId, documentId, editionId, activityPrefix, activityType]);
}
