import { computePosition, flip, offset, shift } from "@floating-ui/dom";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import ILog from "app/_lib/global/Log";
import { PA } from "app/_types/PATypes";
import { createContext, useCallback, useState } from "react";
import MagnifyingGlass from "../_components/MagnifyingGlass";

interface FocusItemProviderProps {
   children: React.ReactNode;
   editable: boolean;
   token: string | undefined;
   role: PA.RoleQueries | undefined;
}
export default function FocusItemProvider({ children, editable, token, role }: FocusItemProviderProps) {
   const { focusedItemId, setFocusedItemId } = useFocusItemCallbacks({});
   // const { toolbarEditor } = useContext(ToolbarEditorContext);
   // const { editorDocumentId } = useContext(EditorDocumentIdContext);
   ILog.v("FocusItemProvider", { token, role });
   return (
      <FocusItemContext.Provider value={{ focusedItemId }}>
         <FocusItemSetterContext.Provider value={{ setFocusedItemId }}>
            <_DivUNSAFE id="focus-item-provider" className="flex flex-row h-full">
               {children} <MagnifyingGlass editable={editable} token={token} role={role} />
            </_DivUNSAFE>
         </FocusItemSetterContext.Provider>
      </FocusItemContext.Provider>
   );
}
export type FocusItemSetterContextType = {
   setFocusedItemId: (id: string | null) => void;
};

export const FocusItemSetterContext = createContext<FocusItemSetterContextType>({
   setFocusedItemId: () => {}
});

export type FocusItemContextType = {
   focusedItemId: string | null;
};

export const FocusItemContext = createContext<FocusItemContextType>({
   focusedItemId: null
});

type FocusItemCallbacks = {};
function useFocusItemCallbacks({}: FocusItemCallbacks) {
   const [focusedItemId, set] = useState<string | null>(null);
   const setFocusedItemId = useCallback(
      (id: string | null) => {
         set(id);
      },
      [set]
   );

   // useEffect(() => {
   //    if (!containerRef?.current) return;
   //    const floating = document.getElementById(`link-focus`);

   //    containerRef.current.addEventListener("mousedown", (e) => {
   //       const { clientX, clientY, target } = e;

   //       if (!floating) {
   //          ILog.v("no floating element", { floating });

   //          return;
   //       }
   //       if (floating.contains(e.target as Element | null)) {
   //          return;
   //       }
   //       //@ts-expect-error
   //       const focusItemId = target?.pmViewDesc?.mark?.attrs?.focusItemId;
   //       if (!focusItemId) {
   //          ILog.v("no focusItemId", { focusItemId, target, floating });
   //          floating.style.transition = "all 0.5s";
   //          floating.style.scale = "0";
   //          return;
   //       }
   //       setFocusedItemId(focusItemId);
   //       ILog.v("focusItemId", { focusItemId, target });
   //       const virtualEl = {
   //          getBoundingClientRect() {
   //             return {
   //                width: 0,
   //                height: 0,
   //                x: clientX,
   //                y: clientY,
   //                left: clientX,
   //                right: clientX,
   //                top: clientY,
   //                bottom: clientY
   //             };
   //          }
   //       };

   //       computePosition(virtualEl, floating, {
   //          placement: "bottom",
   //          middleware: [offset(0), flip(), shift()]
   //       }).then(({ x, y }) => {
   //          Object.assign(floating.style, {
   //             top: `${y}px`,
   //             left: `${x}px`,
   //             display: "block",
   //             scale: "1"
   //          });
   //          floating.addEventListener("mouseleave", (e) => {
   //             if (floating.contains(e.target as Element | null)) {
   //                return;
   //             }
   //             floating.style.transition = "all 0.5s";
   //             floating.style.scale = "0";
   //          });
   //       });
   //    });
   // }, [containerRef?.current]);

   return { focusedItemId, setFocusedItemId };
}

type ToggleViewFocusItemProps = {
   show: boolean;
   clientX: number;
   clientY: number;
};
export function toggleShowFocusItem({ show, clientX, clientY }: ToggleViewFocusItemProps) {
   const floating = document.getElementById(`link-focus`);

   if (!floating) {
      ILog.v("no floating element", { floating });

      return;
   }

   if (!show) {
      floating.style.transition = "all 0.5s";
      floating.style.scale = "0";
      return;
   }

   const virtualEl = {
      getBoundingClientRect() {
         return {
            width: 0,
            height: 0,
            x: clientX,
            y: clientY,
            left: clientX,
            right: clientX,
            top: clientY,
            bottom: clientY
         };
      }
   };

   computePosition(virtualEl, floating, {
      placement: "bottom",
      middleware: [offset(0), flip(), shift()]
   }).then(({ x, y }) => {
      Object.assign(floating.style, {
         top: `${y}px`,
         left: `${x}px`,
         display: "block",
         scale: "1"
      });
      floating.addEventListener("mouseleave", (e) => {
         if (floating.contains(e.target as Element | null)) {
            return;
         }
         floating.style.transition = "all 0.5s";
         floating.style.scale = "0";
      });
   });
}
