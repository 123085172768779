import { HocuspocusProvider } from "@hocuspocus/provider";
import { Editor as ReactEditor } from "@tiptap/react";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Y from "yjs";
import {
   EditorDocumentIdSetterContext,
   EditorDocumentIdSetterContextType,
   EditorDroppedNodesSetterContext,
   EditorDroppedNodesSetterContextType,
   ToolbarSetterContext,
   ToolbarToggleSetterContext
} from "../_contexts/ToolbarProvider";
import { HocuspocusWebSocketProviderSingleton } from "../_services/HocusWebsocketProvider";

import { Editor, Extension, Extensions } from "@tiptap/core";
import Blockquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import Code from "@tiptap/extension-code";
import CodeBlock from "@tiptap/extension-code-block";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import Document from "@tiptap/extension-document";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import { AppStore, useAppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { useQueryStates } from "nuqs";
// import "prosemirror-view/style/prosemirror.css";
import { toast } from "react-toastify";
import { useLocalStorage } from "usehooks-ts";
import { v4 } from "uuid";
import { DashboardParams } from "../_components/DashboardParams";
import { WriterProps } from "../_components/Writer";
import { ActivitySpecNode } from "../_components/nodes/ActivitySpecNode";
import { AssetNode } from "../_components/nodes/AssetNode";
import { ComparisonNode } from "../_components/nodes/ComparisonNode";
import { ActivitySearch } from "../_components/plugins/ActivitySearch";
import { useCommandsPlugin } from "../_components/plugins/CommandsPlugin";
import { FocusItemExtension } from "../_components/plugins/FocusItem";
import { NodeDeletionHandlerExtension } from "../_components/plugins/NodeDeletionHandlerExtension";
import { SearchPlugin } from "../_components/plugins/SearchPlugin";
import { FocusItemSetterContext, FocusItemSetterContextType } from "../_contexts/FocusItemProvider";
import { isPotluckDeliverableTagURL } from "../_helpers/handleRegex";

interface UseEditorProps extends WriterProps {
   collaboratorName: string | undefined;
   userId: string | undefined;
   onFocusProvider?: (provider: HocuspocusProvider) => void;
}

export default function useTiptapEditor(props: UseEditorProps) {
   const yDocRef = useRef<Y.Doc>(new Y.Doc());
   const [provider, setProvider] = useState<HocuspocusProvider | null>(null);

   const [editorInstance, setEditorInstance] = useState<ReactEditor | null>(null);
   const [, setOpen] = useLocalStorage("toggle-right-nav", false);
   useHocusPocusProvider({ yDocRef, editorInstance, provider, setProvider, ...props });
   const { createEditor } = useCreateEditor({ yDocRef, editorInstance, setEditorInstance, provider, setOpen, setProvider, ...props });
   ILog.v("useEditor", { props, yDocRef, editorInstance });

   useEffect(() => {
      if (provider) {
         props.onFocusProvider?.(provider);
      }
   }, [provider]);
   return { editorInstance, provider, yDoc: yDocRef.current, createEditor };
}

interface UseCreateEditorProps extends UseEditorProps {
   yDocRef: React.MutableRefObject<Y.Doc>;
   editorInstance: ReactEditor | null;
   setEditorInstance: (editor: ReactEditor | null) => void;
   provider: HocuspocusProvider | null;
   CommandsPlugin?: Extension<any, any>;
   setOpen: Dispatch<SetStateAction<boolean>>;
   setProvider: Dispatch<SetStateAction<HocuspocusProvider | null>>;
}

function useCreateEditor(props: UseCreateEditorProps) {
   const {
      yDocRef,
      documentId,
      editorInstance,
      provider,
      editable,
      shouldConnectHocus,
      token,
      setProvider,
      expectAuthentication,
      addTopPriorityExtensions,
      autoFocus,
      collaboratorName,
      contentOverride,
      saveContentVersion,
      setEditorInstance,
      setOpen,
      SpecNodeAttributes,
      ToolbarSlot,
      activityType,
      editorClassAttributes,
      includeCommandItems,
      orientationOverride,
      shouldSetEditor,
      shouldSetEditorOnCreate,
      userId,
      onFocusProvider
   } = props;
   const { invalidateToolbarState } = useContext(ToolbarToggleSetterContext);
   const { setToolbarEditor } = useContext(ToolbarSetterContext);
   const CommandsPlugin = useCommandsPlugin({ includeCommandItems: props.includeCommandItems });
   const { setDroppedNodes } = useContext(EditorDroppedNodesSetterContext);
   const { setFocusedItemId } = useContext(FocusItemSetterContext);
   const { setEditorDocumentId } = useContext(EditorDocumentIdSetterContext);
   const [{ focus_item_id, focus_comment_thread_id }, setValues] = useQueryStates(DashboardParams);
   const [staleEditor, setStaleEditor] = useState<ReactEditor | null>(null);
   const store = useAppStore();
   // useWindowUnload({
   //    callOnCleanup: true,
   //    handler: () => {
   //       ILog.v("useEditor_UNLOAD", { token, editable, provider, editorInstance });
   //       if (provider) {
   //          provider.disconnect();
   //       }
   //       if (editorInstance) {
   //          const droppedSectionAssetIds = editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionAssetIds as Set<string>;
   //          const droppedSectionIds = editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionIds as Set<string>;
   //          const sectionAssetIds = Array.from(droppedSectionAssetIds);
   //          const sectionIds = Array.from(droppedSectionIds);
   //          ILog.v("useEditor_DESTROY", { sectionAssetIds, sectionIds });
   //          if (!token) {
   //             ILog.w("useEditor_DESTROY_no_token", {});
   //             return;
   //          }
   //          deleteSectionAndSectionAsset({ sectionAssetIds, sectionIds, token });
   //          editorInstance.destroy();
   //          setEditorInstance(null);
   //       }
   //    }
   // });

   const _createEditor = useCallback(
      () =>
         createEditor({
            store,
            invalidateToolbarState,
            setToolbarEditor,
            CommandsPlugin,
            setDroppedNodes,
            setFocusedItemId,
            setEditorDocumentId,
            setProvider,
            focus_item_id,
            focus_comment_thread_id,
            setValues,
            ydocRef: yDocRef,
            editorInstance,
            setStaleEditor,
            addTopPriorityExtensions,
            autoFocus,
            collaboratorName,
            contentOverride,
            documentId,
            editable,
            provider,
            saveContentVersion,
            setEditorInstance,
            setOpen,
            token,
            yDocRef,
            activityType,
            editorClassAttributes,
            expectAuthentication,
            includeCommandItems,
            orientationOverride,
            shouldConnectHocus,
            shouldSetEditor,
            shouldSetEditorOnCreate,
            SpecNodeAttributes,
            ToolbarSlot,
            userId,
            onFocusProvider
         }),
      [
         store,
         invalidateToolbarState,
         setToolbarEditor,
         CommandsPlugin,
         setDroppedNodes,
         setFocusedItemId,
         setEditorDocumentId,
         setProvider,
         focus_item_id,
         focus_comment_thread_id,
         setValues,
         yDocRef,
         editorInstance,
         setStaleEditor,
         addTopPriorityExtensions,
         autoFocus,
         collaboratorName,
         contentOverride,
         documentId,
         editable,
         provider,
         saveContentVersion,
         setEditorInstance,
         setOpen,
         token,
         activityType,
         editorClassAttributes,
         expectAuthentication,
         includeCommandItems,
         orientationOverride,
         shouldConnectHocus,
         shouldSetEditor,
         userId,
         onFocusProvider
      ]
   );
   useEffect(() => {
      ILog.v("useEditor_try_createEditor", { provider });
      const hocusReady = !!shouldConnectHocus ? !!provider : true;
      if (!hocusReady) {
         ILog.v("NOT_createEditor_hocus isn't ready", {});
         return;
      }
      if (expectAuthentication && !token) {
         return;
      }
      _createEditor();
   }, [documentId, provider, CommandsPlugin, shouldConnectHocus, focus_comment_thread_id, expectAuthentication, token, editable]);

   useEffect(() => {
      if (editorInstance) {
         ILog.v("useEditor_EDITABLE", { editable, currentlyEditable: editorInstance.isEditable });
         editorInstance.setEditable(editable, true);
      }
   }, [editable]);

   useEffect(() => {
      if (editorInstance && staleEditor) {
         staleEditor.destroy();
         setStaleEditor(null);

         ILog.v(`${documentId}_onCreate_destroyed`, { editorInstance, staleEditor });
      }
   }, [editorInstance, staleEditor]);
   return { createEditor: _createEditor };
}

type CreateEditorProps = {
   invalidateToolbarState: () => void;
   setToolbarEditor: (editor: Editor) => void;
   setDroppedNodes: EditorDroppedNodesSetterContextType["setDroppedNodes"];
   setFocusedItemId: FocusItemSetterContextType["setFocusedItemId"];
   setEditorDocumentId: EditorDocumentIdSetterContextType["setEditorDocumentId"];
   setProvider: Dispatch<SetStateAction<HocuspocusProvider | null>>;
   ydocRef: React.MutableRefObject<Y.Doc>;
   focus_item_id: string | null;
   focus_comment_thread_id: string | null;

   setValues: (values: Record<string, string>) => void;
   setStaleEditor: Dispatch<SetStateAction<Editor | null>>;
   store: AppStore;
} & UseCreateEditorProps;
function createEditor({
   yDocRef,
   provider,
   setEditorInstance,
   setToolbarEditor,
   invalidateToolbarState,
   editorInstance,
   CommandsPlugin,
   documentId,
   setDroppedNodes,
   editable,
   token,
   setOpen,
   setFocusedItemId,
   setEditorDocumentId,
   shouldConnectHocus,
   contentOverride,
   shouldSetEditor = true,
   focus_comment_thread_id,
   ydocRef,
   setValues,
   saveContentVersion,
   editorClassAttributes,
   addTopPriorityExtensions,
   collaboratorName,
   setStaleEditor,
   shouldSetEditorOnCreate,
   store,
   onFocusProvider
}: CreateEditorProps) {
   const hocusReady = !!shouldConnectHocus ? !!provider : true;
   if (!hocusReady || !yDocRef.current || documentId === undefined) {
      ILog.w("NOT_createEditor", {});
      return;
   }
   // new IndexeddbPersistence(documentId || "", yDocRef.current);
   ILog.v("createEditor", { provider, contentOverride });
   let extensions = [...addTopPriorityExtensions] as Extensions;
   if (provider) {
      extensions.push(
         Collaboration.configure({
            document: provider.document
         })
      );
   } else {
      extensions.push(
         Collaboration.configure({
            document: yDocRef.current
         })
      );
   }
   extensions.push(
      Paragraph,

      Text,
      SearchPlugin,
      Gapcursor,
      Document,
      Dropcursor,
      Strike,
      Italic,
      CodeBlock,
      Code,
      Bold,
      OrderedList,
      ListItem,
      HorizontalRule,
      Heading,
      HardBreak,
      BulletList,
      Blockquote,
      Table.configure({
         resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      NodeDeletionHandlerExtension,

      ComparisonNode,
      FocusItemExtension.configure({
         store,
         HTMLAttributes: {},
         onFocusItemActivated: (props) => {
            setOpen(true);
            setFocusedItemId(props.focusItemId);
            ILog.v("onFocusItemActivated", { ...props });
            if (props.editor) {
               ILog.v("onFocusItemActivated", {});
               setToolbarEditor(props.editor);
            }
            if (props.focusItemId) {
               setValues({ focus_comment_thread_id: props.focusItemId, focus_item_id: props.focusItemId, revalidate: v4() });
            }
         },
         onFocusItemUpdated: (props) => {
            if (props.editor) {
               ILog.v("onFocusItemActivated", {});
               setToolbarEditor(props.editor);
            }
            setValues({ revalidate: v4() });
         },
         protocols: ["https"],
         defaultProtocol: "https",
         autolink: true,
         openOnClick: true,
         linkOnPaste: true,
         shouldAutoLink: (url) => {
            const bool = !isPotluckDeliverableTagURL(url);
            ILog.v("shouldAutoLink", { url, bool });
            return bool;
         }
      }),
      ActivitySpecNode,
      AssetNode,
      ActivitySearch
      // PasteHandleExtension

      // Mention.configure({
      //    HTMLAttributes: {
      //       class: "mention"
      //    },
      //    suggestion
      // }),
      // ActivitySearch.configure({
      //    suggestion: {
      //       char: "@"
      //    }
      // }),
      // PreventNodeDeletionExtension,
   );
   if (CommandsPlugin) {
      extensions.push(CommandsPlugin);
   }
   if (collaboratorName) {
      ILog.v("createEditor_CollaborationCursor", { collaboratorName });
      extensions.push(
         CollaborationCursor.configure({
            provider: provider,

            render: (user) => {
               ILog.v("createEditor_CollaborationCursor_render", { user });
               const cursor = document.createElement("span");
               cursor.classList.add("collaboration-cursor__caret", "border-l-2", "border-black", "rounded-md", "w-2", "h-[1.5rem]", "relative", "select-none", "z-10");

               const label = document.createElement("div");
               label.classList.add(
                  "collaboration-cursor__label",
                  "absolute",
                  "-top-full",
                  "left-0",
                  "text-base",
                  "px-1",
                  "rounded-xs",
                  "text-nowrap",
                  "text-underline",
                  "select-none",
                  "bg-green-400",
                  "text-white",
                  "z-20"
               );

               label.insertBefore(document.createTextNode(`${user.name} - ${user.canEdit ? "Editing" : "Viewing"}`), null);

               cursor.insertBefore(label, null);
               ILog.v("createEditor_CollaborationCursor_render_FINAL", { user, cursor, label });
               return cursor;
            },
            selectionRender(user) {
               ILog.v("createEditor_CollaborationCursor_selectionRender", { user });
               return {
                  nodeName: "span",
                  class: "collaboration-cursor__selection",
                  style: `background-color: ${user.color}`,
                  "data-user": user.name
               };
            },
            user: {
               name: collaboratorName,
               color: "#39B54A", // "#f783ac"
               canEdit: editable
            }
         })
      );
   }

   ILog.v("PREFIX_Writer", {});
   const editor: ReactEditor = new ReactEditor({
      extensions,
      autofocus: false,

      editable: editable,
      injectCSS: false,
      editorProps: {
         attributes: {
            class: editorClassAttributes || `editor editor-content h-fit max-w-[100%] mx-auto focus:outline-hidden  ${saveContentVersion ? "border-black border-2 p-2" : ""}`,
            editorDocumentId: documentId || "",
            tabindex: "0",
            gestureId: focus_comment_thread_id || "",
            instanceId: v4()
         }
      },

      onUpdate(props) {
         const meta = props?.transaction?.getMeta("uiEvent");
         ILog.v(`${documentId}_onUpdate`, { extensionStorage: props.editor.extensionStorage });
         ILog.v(`${documentId}_onUpdate`, { ...props, meta, documentId, before: props.transaction.before });
         if (meta === "drop") {
            //@ts-expect-error
            const nodeId = props?.transaction?.selection?.node?.attrs?.id;

            if (nodeId) {
               ILog.v(`${documentId}_onUpdate`, { ...props, meta, nodeId, documentId, setDroppedNodes });

               setDroppedNodes((prev) => {
                  let nodes = prev || [];
                  if (documentId) {
                     nodes.push({ id: nodeId, newDocumentId: documentId });
                     ILog.v("setDroppedNodes", { nodes });
                  }
                  return nodes;
               });
            }
         }
      },

      onFocus: (props) => {
         // invalidateToolbarState();
         if (shouldSetEditor) {
            ILog.v(`${documentId}_onFocus`, { ...props });
            setToolbarEditor(props.editor);
            setEditorDocumentId(documentId);
         }
         if (onFocusProvider && provider) {
            onFocusProvider(provider);
         }
      },
      onSelectionUpdate(props) {
         ILog.v(`${documentId}_onSelectionUpdate`, { ...props });

         invalidateToolbarState();
      },
      onBlur(props) {
         ILog.v(`${documentId}_onBlur`, { ...props });
      },
      onCreate(props) {
         ILog.v(`${documentId}_onCreate`, { ...props });
         props.editor.view.dom.setAttribute("spellcheck", "false");
         props.editor.view.dom.setAttribute("autocomplete", "off");
         props.editor.view.dom.setAttribute("autocapitalize", "off");
         if (!!contentOverride && !shouldConnectHocus) {
            const bufferArray = Buffer.from(contentOverride, "base64");
            const uint8Array = new Uint8Array(bufferArray);

            ILog.v("applyUpdate", {});
            Y.applyUpdate(yDocRef.current, uint8Array);
         }
         if (shouldSetEditorOnCreate) {
            setToolbarEditor(props.editor);
            setEditorDocumentId(documentId);
         }
         if (editorInstance && collaboratorName) {
            setStaleEditor(editorInstance);
         }
      },
      onTransaction(props) {
         //@ts-expect-error
         const editorDocumentId = props.editor.options.editorProps.attributes?.editorDocumentId;
         const reload = props.transaction?.getMeta("focusItem")?.reload;
         if (props.transaction.steps?.length > 0) {
            ILog.v(`${documentId}_onTransaction`, { transaction: props.transaction, reload });
         }
         if (!!reload) {
            setValues({ revalidate: v4() });
         }
         if (shouldSetEditor && props.editor && !!reload) {
            setToolbarEditor(props.editor);
            if (documentId) {
               setEditorDocumentId(documentId);
            }
         }
      },
      onDestroy(props) {
         ILog.v(`${documentId}_onDestroy`, { props });
         ydocRef.current = new Y.Doc();
         // setEditorInstance(null);
         // setProvider(null);
         if (editorInstance) {
            const droppedSectionAssetIds = editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionAssetIds as Set<string>;
            const droppedSectionIds = editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionIds as Set<string>;
            const sectionAssetIds = Array.from(droppedSectionAssetIds);
            const sectionIds = Array.from(droppedSectionIds);
            ILog.v("useEditor_DESTROY", { sectionAssetIds, sectionIds, token });
            if (!token) {
               ILog.w("useEditor_DESTROY_no_token", {});
               return;
            }
            // deleteSectionAndSectionAsset({ sectionAssetIds, sectionIds, token });
            // editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionAssetIds = new Set<string>();
            // editorInstance.extensionStorage.nodeDeletionHandler.droppedSectionIds = new Set<string>();
         }
      },

      onPaste(e, slice) {
         ILog.v("onPaste", { e, slice });
      },
      onContentError({ disableCollaboration, editor, error }) {
         ILog.v("onContentError", { error });
         // Removes the collaboration extension.
         disableCollaboration();

         // Since the content is invalid, we don't want to emit an update
         // Preventing synchronization with other editors or to a server
         const emitUpdate = false;

         // Disable the editor to prevent further user input
         editor.setEditable(false, emitUpdate);
         toast.error("Please refresh the page.");

         // Maybe show a notification to the user that they need to refresh the app
      },
      onBeforeCreate(props) {
         ILog.v("onBeforeCreate", { props });
      },
      onDrop(e, slice, moved) {
         ILog.v("onDrop", { e, slice, moved });
      }
   });

   setEditorInstance(editor);
}

interface CreateHocusProviderProps extends UseEditorProps {
   yDocRef: React.MutableRefObject<Y.Doc>;
   editorInstance?: ReactEditor | null;
   provider: HocuspocusProvider | null;
   setProvider: (provider: HocuspocusProvider | null) => void;
}

function createHocusProvider({
   documentId,
   yDocRef,
   token,
   provider,
   setProvider,
   setHasConnectedtoHocus,
   saveContentVersion,
   userId
}: CreateHocusProviderProps & { setHasConnectedtoHocus: Dispatch<SetStateAction<boolean>> }) {
   ILog.v("createHocusProvider", { yDocRef: yDocRef.current, token });
   return new HocuspocusProvider({
      websocketProvider: HocuspocusWebSocketProviderSingleton.getInstance({ saveContentVersion }),
      connect: false,
      name: documentId,
      document: yDocRef.current,
      token,
      preserveConnection: true,

      onAuthenticationFailed(data) {
         ILog.w("HocusProvider_AuthFailed", { data });
         provider?.disconnect();
         setProvider(null);
         setHasConnectedtoHocus(false);
      },
      onSynced() {
         // window.scrollTo(0, 0);
         // editorInstance?.commands.focus("start");
      },
      onStateless(data) {
         ILog.e("HocusProvider_Stateless", { data });
         const payload = JSON.parse(data.payload) as { type: string; userId: string; message: string };
         if (payload.userId === userId) {
            toast.error(payload.message);
         }
      }
   });
}

function useHocusPocusProvider(props: CreateHocusProviderProps) {
   const { documentId, yDocRef, token, expectAuthentication, editorInstance, provider, setProvider, shouldConnectHocus } = props;
   const [hasConnectedToHocus, setHasConnectedtoHocus] = useState(false);
   ILog.v("useHocusPocusProvider", { documentId, provider, token, shouldConnectHocus, expectAuthentication });
   useEffect(() => {
      if (!!provider || !yDocRef.current) return;
      if (expectAuthentication && !token) return;
      if (!shouldConnectHocus) return;

      const newProvider = createHocusProvider({ ...props, setHasConnectedtoHocus });
      if (newProvider) {
         setProvider(newProvider);
      }
      setHasConnectedtoHocus(false);
   }, [expectAuthentication, documentId, editorInstance, token, yDocRef, hasConnectedToHocus, provider, props, shouldConnectHocus, props.userId]);

   useEffect(() => {
      if (provider && !hasConnectedToHocus) {
         if (shouldConnectHocus) {
            ILog.v("useEditor_CONNECT", { providerRef: provider });
            provider.connect();
            setHasConnectedtoHocus(true);
         }
      }
   }, [editorInstance, hasConnectedToHocus, provider, token]);

   useEffect(() => {
      if (token && provider && !!shouldConnectHocus) {
         function updateEditorToken() {
            ILog.v("updateEditorToken", {});
            provider?.disconnect();
            setProvider(null);
            setHasConnectedtoHocus(false);
         }
         updateEditorToken();
      }
   }, [token]);
}
